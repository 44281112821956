import React from "react"
import { graphql, Link } from "gatsby"
import { Layout, Seo } from "@components"
import styled from "styled-components"
import { inner, action_link } from "@css/global"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <NotFoundWrapper>
        <h1>404: Not Found</h1>
        <p>Hmmm - that page doesn't exist</p>
        <Link to="/">Why not head back to our home page instead?</Link>
      </NotFoundWrapper>
    </Layout>
  )
}

export default NotFoundPage

const NotFoundWrapper = styled.div`
  ${inner};
  min-height: calc(100vh - 6.125rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  h1,
  p {
    width: 100%;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  a {
    margin-top: 4.5rem;
    ${action_link};
    text-align: center;
  }
`

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
